<script>
import Swal from "sweetalert2";
import {
  required,
  decimal,
  maxLength,
  requiredIf,
} from "vuelidate/lib/validators";
import Dataset from 'vue-dataset/dist/es/Dataset.js'
import DatasetItem from 'vue-dataset/dist/es/DatasetItem.js'
// import DatasetInfo from 'vue-dataset/dist/es/DatasetInfo.js'
import DatasetPager from 'vue-dataset/dist/es/DatasetPager.js'
// import DatasetSearch from 'vue-dataset/dist/es/DatasetSearch.js'
import DatasetShow from 'vue-dataset/dist/es/DatasetShow.js'
export default {
  props:{
    reloadHierarchy: { }
  },
  components: { Dataset,DatasetItem,/*DatasetInfo,*/DatasetPager,/*  DatasetSearch ,*/ DatasetShow,},
  data() {
    return {
      List_hierarchy: [],
      title: "hierarchy",
      rows:'',  
      active:false,
      selectedRows: null,
      items: [
        {
          text: "hierarchy",
          href: "javascript:void(0)",
        },
        {
          text: "hierarchy",
          active: true,
        },
      ],
      totalPages : 0,
      currentPage: 1,
      pageSize: 5,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: false,
      sortBy: "name",
      fields: [
        { key: "name", sortable: true },
        { key: "type", sortable: true },
        { key: "classe", sortable: true },
      ],
      filters: {
        directionName : { value: '', keys: ['directionName'] },
        divisionName : { value: '', keys: ['divisionName'] },
        departmentdepartementName : { value: '', keys: ['departmentdepartementName'] },
        serviceName : { value: '', keys: ['serviceName'] },
        sectionName : { value: '', keys: ['sectionName'] },
      },
      cols: [
         {
          name: "Direction ",
          field: "direction.directionName",
          sort: ''
        },
        {
          name: "Division",
          field: "division.divisionName",
          sort: ''
        },
        {
          name: "Services",
          field: "department.departmentName",
          sort: ''
        },
        {
          name: "Service",
          field: "service.serviceName",
          sort: ''
        },
        {
          name: "Section",
          field: "section.sectionName",
          sort: ''
        },
      ],
      selectedIndex:-1,




    };
  },

  mounted() {
    this.refreshHierarchie();
  },
 
 watch:{
      selectedRows(){
        if(this.selectedRows.length){
         this.$emit('selectedHierarche',this.selectedRows)
        }else{
          this.$emit('selectedHierarche',[])
        }
      },
      hierarchy:{
          handler(val){
            
          }
          
          
      },
      reloadHierarchy(){
        if(this.reloadHierarchy){
          this.refreshHierarchie();
        }
      }
  },
  methods:{
    selectRow(row,index){
      if(this.selectedIndex == index) {this.selectedRows = []; this.selectedIndex = -1; this.$emit('selectedHierarche',[]) }
      else {
        this.$emit('selectedHierarche',[row])
        this.selectedIndex = index;
      }
    },
    getList_hierarchy(){
      
    },
    refreshHierarchie(){
      let loader = this.$loading.show({
            color: '#000000',
            loader: 'spinner',
            width: 64,
            height: 64,
            backgroundColor: '#ffffff',
            opacity: 0.5,
            zIndex: 999,
          })
     this.$http.get('/organisation/hierarchy/direction/getDirections')
      .then(response => {
          this.bases = response.data;
          this.List_hierarchy = response.data.data
          this.$emit('selectedFristRow',response.data.data[0])
          this.$emit("refreshHierarchyDone",false)
          loader.hide();
          this.selectedRows = []; this.selectedIndex = -1;
      })
      .catch(error => {
        console.log(error)
        loader.hide();
        })
    },
    deleteHierarchy(supName, supUid) {
      Swal.fire({
        title: "Êtes-vous sûr de supprimer " + supName + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Supprimer!",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("/contracts/hierarchy/delete/" + supUid)
            .then((res) => {
              var status = res.data.original.status;
              switch (status) {
                case 200:
                  Swal.fire("Félicitations!", res.data.original.msg, "success");
                  this.refreshHierarchie();
                  break;

                case 500:
                  Swal.fire("Avertissement!", res.data.original.msg, "warning");
                  break;
              }
            })
            .catch((error) => {
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
  }

};
</script>

<template>
 <div class="row">
      <div class="col-12">
        <div class="accordion mb-2" role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block @click="active = !active" style="background:#e5e9f0; color:black !important; border:none !important;">
                <div class="row">
                  <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> Liste des Hiérarchies</div>
                  <div class="col text-right">
                    <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                    <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                  </div>
                </div>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-1" :visible="active" accordion="my-accordion" role="tabpanel">
              <b-card-body class="shadow-lg">
              <div class="table-responsive mb-0 shadow">
                <dataset v-slot="{ ds }" :ds-data="List_hierarchy" >
                  <div class="row">
                    <div class="col-md-6 mb-2 mb-md-0">
                    </div>
                    <div class="col-md-6">
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="">
                        <table class="table table-hover d-md-table">
                          <thead>
                            <tr>
                              <th v-for="(th) in cols" :key="th.field">
                                {{ th.name }} 
                              </th>
                            </tr>
                          </thead>
                          <dataset-item tag="tbody">
                            <template #default="{ row, rowIndex }">
                              <tr @click="selectRow(row,rowIndex)" :class="{'bg-soft-info':selectedIndex == rowIndex}" style="cursor:pointer">
                                <td>{{ row.direction.directionName }}</td>
                                <td>{{ row.division.divisionName }} </td>
                                <td>{{ row.department.departmentName }}</td>
                                <td>{{ row.service.serviceName }}</td>
                                <td>{{ row.section.sectionName }}</td>
                              </tr>
                            </template>
                          </dataset-item>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-md-row flex-column justify-content-between align-items-center">
                    <dataset-show :ds-show-entries="5" />
                    <dataset-pager />
                  </div>
                </dataset>
                <!--   <v-table
                    class="table table-hover"
                    :data="List_hierarchy"   
                    :currentPage.sync="currentPage"
                    :pageSize="5"
                    @totalPagesChanged="totalPages = $event"
                    selectionMode="single"
                    selectedClass="table-info"
                    @selectionChanged="selectedRows = $event"
                  >
                  <thead slot="head" class="thead-light">
                    <tr>
                      <th>Direction</th>
                      <th>Division</th>
                      <th>Services</th>
                      <th>Service</th>
                      <th>Section</th>
                    </tr>
                  </thead>
                  <tbody slot="body" slot-scope="{displayData}">
                    <v-tr v-for="row in displayData" :key="row.rowID"  :row="row">
                      <td>{{ row.direction.directionName }}</td>
                      <td>{{ row.division.divisionName }}</td>
                      <td>{{ row.department.departmentName }}</td>
                      <td>{{ row.service.serviceName }}</td>
                      <td>{{ row.section.sectionName }}</td>                      
                    </v-tr>
                  </tbody>
                </v-table>
              
                <smart-pagination
                  :currentPage.sync="currentPage"
                  :totalPages="totalPages"
                /> -->
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      
      </div>
    </div>

</template>