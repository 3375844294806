<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import hierarchyTable from './components/hierarchyTable';
import Swal from "sweetalert2";
import {required,decimal,maxLength,requiredIf} from "vuelidate/lib/validators";
// import func from 'vue-editor-bridge';
export default {
  page: {
    title: "Referentiels",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader , hierarchyTable},
  data() {
    return {
      reloadHierarchy: false,
      tableData: [],
      title: "Hiérarchie",
      services: [],
      disabled : true,
      canDelte : false,
      disabledAdd : false,
      disabledUpdate : false,
      disabledCancel : true,
      division_ids : false,
      departement_ids : false,
      service_ids : false,
      items: [
        {
          text: "Master Data",
          to: { name: 'master_data' }
        },
        {
          text: "Referentiels",
          to: { name: 'master_data.referentiels' }
        },
        {
          text: "Hiérarchie",
          active: true
        }
      ],
      form: {
        direction :{id:'', name : '', description : ''},
        division :{id:'', name : '', description : ''},
        departement :{id:'', name : '', description : ''},
        service :{id:'', name : '', description : ''},
        section :{id:'', name : '', description : ''},
      },
      lastSelect : undefined,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      paHptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: false,
      sortBy: "name",
      fields: [
        { key: "name", sortable: true },
        { key: "address", sortable: true },
        { key: "analytic_account", sortable: true },
      ],
      submitted: false,
    };
  },
   validations: {
        form: {
          direction : {
            name : {
              required,
              maxLength: maxLength(64),
            },
            description :{
              
              maxLength : maxLength(190)
            }
          },
          division : {
            name : {
              maxLength: maxLength(64),
            },
            description :{
              maxLength : maxLength(190)
            }
          },
          departement : {
            required: requiredIf(function() {
                return this.form.service.name
              }),
            name : {
              maxLength: maxLength(64),
            },
            description :{
              maxLength : maxLength(190)
            }
          },
          service : {
            name : {
              
              maxLength: maxLength(64),
            },
            description :{
              
              maxLength : maxLength(190)
            }
          },
          section : {
            name : {
              maxLength: maxLength(64),
            },
            description :{
              
              maxLength : maxLength(190)
            }
          },
        },
      },

  methods: {
    /**
     * Search the table data with search input
     */
    addBtn(){
      this.disabled       = false;
      this.disabledAdd    = true;
      this.disabledUpdate = false;
      this.clearForms();
      this.canDelte       = true;
      this.disabledCancel = false;
    },
    duplicateBtn(){
      this.disabled              = false;
      this.disabledAdd           = true;
      this.disabledUpdate        = false;
      this.form.direction.uuid   = "";
      this.form.division.uuid    = "";
      this.form.departement.uuid = "";
      this.form.service.uuid     = "";
      this.form.section.uuid     = "";
      this.canDelte       = true;
      this.disabledCancel        = false;
    },
    editBtn(){
      this.disabledAdd    = false;
      this.disabledUpdate = true;
      this.disabled       = false;
      this.canDelte       = true;
      this.disabledCancel = false;
    },
    cancelBtn(){
      this.disabled       = true;
      this.disabledAdd    = false;
      this.disabledUpdate = false;
      this.canDelte       = false;
      this.disabledCancel = true;
      this.selectedHierarche(this.lastSelect);
    },

    refreshServicesTable(){
      
    },

    addStr(){
      this.submitted = true
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.submitted = false
        let loader = this.$loading.show({
            color: '#000000',
            loader: 'spinner',
            width: 64,
            height: 64,
            backgroundColor: '#ffffff',
            opacity: 0.5,
            zIndex: 999,
          })
        this.$http.post("/organisation/hierarchy/direction/store", this.form)
          .then((res) => {
            var status = res.status;
            if(!res.data.error ){
                // Swal.fire("Félicitations!", "l'opération a été effectué avec succès", "success");
                this.$toast.success("l'opération a été effectué avec succès");
                this.clearForms()
                this.reloadHierarchy = true        
                this.canDelte       = false;
                this.disabled       = true;
                this.disabledAdd    = false;
                this.disabledUpdate = false;
            }else{
                // Swal.fire("Avertissement!", res.data.msg, "warning");
                this.$toast.warning(res.data.msg);
            }
            loader.hide();
          })
          .catch((error) => {
            loader.hide();
            Swal.fire("Erreur!", error.message, "error");
          })
          .finally(() => {});
      }
    },
    updateStr(){
      this.submitted = true
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.submitted = false
        let loader = this.$loading.show({
            color: '#000000',
            loader: 'spinner',
            width: 64,
            height: 64,
            backgroundColor: '#ffffff',
            opacity: 0.5,
            zIndex: 999,
          })
        this.$http.post("/organisation/hierarchy/direction/update", this.form)
          .then((res) => {
            var status = res.status;
            if(!res.data.error ){
                // Swal.fire("Félicitations!", "l'opération a été effectué avec succès", "success");
                this.$toast.success("l'opération a été effectué avec succès");
                this.clearForms()
                this.reloadHierarchy = true
                this.lastSelect = null;
                this.clearForms()
                this.disabledAdd = false;
                this.disabledUpdate = false;
                this.disabled = true;
            }else{
                // Swal.fire("Avertissement!", res.data.msg, "warning");
                this.$toast.warning(res.data.msg);
            }
            loader.hide();
          })
          .catch((error) => {
            loader.hide();
            Swal.fire("Erreur!", error.message, "error");
          })
          .finally(() => {});
      }
    },
    selectedHierarche: function(selectedRows){
      this.clearForms();
      this.canDelte       = true;
      this.disabled       = true;
      if(selectedRows.length> 0){
        this.canDelte       = false;
        this.disabled       = true;
        this.disabledAdd    = false;
        this.disabledUpdate = false;
        this.form.direction.id = selectedRows[0].direction.directionCode ; this.form.direction.name  = selectedRows[0].direction.directionName ; this.form.direction.description = selectedRows[0].direction.directionDescription;
        this.form.division.id = selectedRows[0].division.divisionCode ; this.form.division.name =  selectedRows[0].division.divisionName ; this.form.division.description = selectedRows[0].division.divisionDescription ;
        this.form.departement.id = selectedRows[0].department.departmentCode ; this.form.departement.name = selectedRows[0].department.departmentName ;  this.form.departement.description = selectedRows[0].department.departmentDescription ;
        this.form.service.id = selectedRows[0].service.serviceCode ; this.form.service.name = selectedRows[0].service.serviceName ; this.form.service.description = selectedRows[0].service.serviceDescription ;
        this.form.section.id = selectedRows[0].section.sectionCode ; this.form.section.name =  selectedRows[0].section.sectionName ; this.form.section.description =  selectedRows[0].section.sectionDescription ;
        
        this.lastSelect       = selectedRows
      }
    },

    clearForms(){
      this.form.direction.id = "" ; this.form.direction.name  = "" ; this.form.direction.description = "" ;
      this.form.division.id = "" ; this.form.division.name =  "" ; this.form.division.description = "" ;
      this.form.departement.id = '' ; this.form.departement.name = "" ;  this.form.departement.description = "" ;
      this.form.service.id = "" ; this.form.service.name = "" ; this.form.service.description = "" ;
      this.form.section.id = "" ; this.form.section.name =  "" ; this.form.section.description =  "" ;
    },

    selectedFristRow(rowSelected){
      this.selectedHierarche(rowSelected)
    },
    removeDirection(){
      if(this.form.direction.id == "" || this.form.direction.id == null)
      { 
        Swal.fire("Erreur!","Aucun élément n'est sélectionné","error")
      }else
      Swal.fire({
        title: "Êtes-vous sûr de supprimer : " + this.form.direction.name + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#B8CCD7",
        cancelButtonText: "Annuler",
        confirmButtonText: "Oui!",
      }).then((result) => { 
        if (result.value) {
          let loader = this.$loading.show({
            color: '#000000',
            loader: 'spinner',
            width: 64,
            height: 64,
            backgroundColor: '#ffffff',
            opacity: 0.5,
            zIndex: 999,
          })
          this.$http
            .post("/organisation/hierarchy/direction/delete" ,{'code':this.form.direction.id})
            .then((response) => {
              var data = response.data;
              if(data.errors != 0){
                // Swal.fire("Erreur!", data.msg, "error")
                this.$toast.warning(data.msg);
              }else{
                // Swal.fire("Félicitations!", "l'opération a été effectué avec succès", "success");
                this.$toast.success("l'opération a été effectué avec succès");
                this.clearForms()
                 this.reloadHierarchy = true
                this.lastSelect = null;
                    
              }
              loader.hide();
              })
              .catch((error) => {
                loader.hide();
                Swal.fire("Erreur!", error, "error")
            })
            .finally(() => {});
        }
      });
    },
    removeDivision(){
      if(this.form.division.id == "" || this.form.division.id == null)
      { 
        Swal.fire("Erreur!","Aucun élément n'est sélectionné","error")
      }else
      Swal.fire({
        title: "Êtes-vous sûr de supprimer : " + this.form.division.name + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#B8CCD7",
        cancelButtonText: "Annuler",
        confirmButtonText: "Oui!",
      }).then((result) => {
        if (result.value) {
          let loader = this.$loading.show({
            color: '#000000',
            loader: 'spinner',
            width: 64,
            height: 64,
            backgroundColor: '#ffffff',
            opacity: 0.5,
            zIndex: 999,
          })
          this.$http
            .post("/organisation/hierarchy/division/delete" ,{'code':this.form.division.id})
            .then((response) => {
              var data = response.data;
              if(data.errors != 0){
                // Swal.fire("Erreur!", data.msg, "error")
                this.$toast.warning(data.msg);
              }else{
                // Swal.fire("Félicitations!", "l'opération a été effectué avec succès", "success");
                this.$toast.success("l'opération a été effectué avec succès");
                this.reloadHierarchy = true
                this.lastSelect = null;
                    
                this.form.division.id = ""
                this.form.division.name = "";
                this.form.division.description = "";
              }
              loader.hide();
              })
              .catch((error) => {
                loader.hide();
                Swal.fire("Erreur!", error, "error")
            })
            .finally(() => {});
        }
      });
    },
    removeDepartement(){
      if(this.form.departement.id == "" || this.form.departement.id == null)
      { 
        Swal.fire("Erreur!","Aucun élément n'est sélectionné","error")
      }else
      Swal.fire({
        title: "Êtes-vous sûr de supprimer : " + this.form.departement.name + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#B8CCD7",
        cancelButtonText: "Annuler",
        confirmButtonText: "Oui!",
      }).then((result) => { 
        if (result.value) {
          let loader = this.$loading.show({
            color: '#000000',
            loader: 'spinner',
            width: 64,
            height: 64,
            backgroundColor: '#ffffff',
            opacity: 0.5,
            zIndex: 999,
          })
          this.$http
            .post("/organisation/hierarchy/departement/delete" ,{'code':this.form.departement.id})
            .then((response) => {
              var data = response.data;
              if(data.errors != 0){
                // Swal.fire("Erreur!", data.msg, "error")
                this.$toast.warning(data.msg);
              }else{
                // Swal.fire("Félicitations!", "l'opération a été effectué avec succès", "success");
                this.$toast.success("l'opération a été effectué avec succès");
                 this.reloadHierarchy = true
                this.lastSelect = null;
                    
                 this.form.departement.id = ""
                this.form.departement.name = "";
                this.form.departement.description = "";
              }
              loader.hide();
              })
              .catch((error) => {
                loader.hide();
                Swal.fire("Erreur!", error, "error")
            })
            .finally(() => {});
        }
      });
    },
    removeService(){
      if(this.form.service.id == "" || this.form.service.id == null)
      { 
        Swal.fire("Erreur!","Aucun élément n'est sélectionné","error")
      }else
      Swal.fire({
        title: "Êtes-vous sûr de supprimer : " + this.form.service.name + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#B8CCD7",
        cancelButtonText: "Annuler",
        confirmButtonText: "Oui!",
      }).then((result) => { 
        if (result.value) {
          let loader = this.$loading.show({
            color: '#000000',
            loader: 'spinner',
            width: 64,
            height: 64,
            backgroundColor: '#ffffff',
            opacity: 0.5,
            zIndex: 999,
          })
          this.$http
            .post("/organisation/hierarchy/service/delete" ,{'code':this.form.service.id})
            .then((response) => {
              var data = response.data;
              if(data.errors  != 0){
                // Swal.fire("Erreur!", data.msg, "error")
                this.$toast.warning(data.msg);
              }else{
                // Swal.fire("Félicitations!", "l'opération a été effectué avec succès", "success");
                this.$toast.success("l'opération a été effectué avec succès");
                 this.reloadHierarchy = true
                this.lastSelect = null;
                    
                this.form.service.id = ""
                this.form.service.name = "";
                this.form.service.description = "";
              }
              loader.hide();
              })
              .catch((error) => {
                loader.hide();
                Swal.fire("Erreur!", error, "error")
            })
            .finally(() => {});
        }
      });
    },
    removeServices(){
      if(this.form.section.id == "" || this.form.section.id == null)
      { 
        Swal.fire("Erreur!","Aucun élément n'est sélectionné","error")
      }else
      Swal.fire({
        title: "Êtes-vous sûr de supprimer : " + this.form.section.name + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#B8CCD7",
        cancelButtonText: "Annuler",
        confirmButtonText: "Oui!",
      }).then((result) => { 
        if (result.value) {
          let loader = this.$loading.show({
            color: '#000000',
            loader: 'spinner',
            width: 64,
            height: 64,
            backgroundColor: '#ffffff',
            opacity: 0.5,
            zIndex: 999,
          })
          this.$http
            .post("/organisation/hierarchy/section/delete" ,{'code':this.form.section.id})
            .then((response) => {
              var data = response.data;
              if(data.errors != 0){
                // Swal.fire("Erreur!", data.msg, "error")
                this.$toast.warning(data.msg);
              }else{
                // Swal.fire("Félicitations!", "l'opération a été effectué avec succès", "success");
                this.$toast.success("l'opération a été effectué avec succès");
                this.reloadHierarchy          = true
                this.lastSelect               = null;
                this.form.section.id          = ""
                this.form.section.name        = "";
                this.form.section.description = "";
                this.lastSelect = null;
                this.clearForms()
                this.disabledAdd = false;
                this.disabledUpdate = false;
                this.disabled = true;
              }
              loader.hide();
              })
              .catch((error) => {
                loader.hide();
                Swal.fire("Erreur!", error, "error")
            })
            .finally(() => {});
        }
      });
    },
    
  }
};
</script>
<style >
  .finish-button{
    background-color:#43A047 !important;
    border-color: #43A047 !important;
  }
  #StickyH {
    position: fixed;
    right: 0;
    top: 50%;
    width: 8em;
    margin-top: -2.5em;
    z-index: 999;
    list-style: none;
  }
  .rounded-pillH{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    border-radius: 50% !important;
  }
  .btn-addH{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #044A72 ;
  }
  .btn-duplicateH{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #8FC412 ;
  }
  .btn-editH{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #FFB236 ;
  }
  .btn-deleteH #dropdown-dropleft__BV_toggle_{
     border-radius: 50% !important;
  }
  .btn-deleteH{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #D61337;
  }
   .btn-cancelH{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #B8CCD7 ;
  }
  .btn-uploadH{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #2A5769 ;
  }
  .btn-downloadH{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #21BF92;
  }
  .btn-printH{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #B8CCD7 ;
  }
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
      <hierarchy-table :reloadHierarchy="reloadHierarchy" @refreshHierarchyDone="reloadHierarchy = $event" @selectedHierarche="selectedHierarche" @selectedFristRow="selectedHierarche"  />

    <div class="row">
        <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
              <ul id="StickyH">
                <li>
                  <button type="button"  :disabled="disabledAdd  && !$can('create_hierarchie')" @click="addBtn" class="btn btn-addH rounded-pillH float-right mr-1 mt-1">
                  <i class="fas fa-plus text-white"></i>
                  </button>
                </li>
                <li>
                  <button type="button"  :disabled="canDelte  && !$can('update_hierarchie')" @click="duplicateBtn" class="btn btn-duplicateH rounded-pillH float-right mr-1 mt-1">
                  <i class="far fa-copy text-white"></i>
                  </button>
                </li>
                <li>
                  <button type="button"  :disabled="canDelte  && !$can('delete_hierarchie')" @click="editBtn" class="btn btn-editH rounded-pillH float-right mr-1 mt-1">
                    <i class="fas fa-pencil-alt text-white" style=""></i>
                  </button>
                </li>
                <li>
                <b-dropdown id="dropdown-dropleft" :disabled="canDelte"  pill class="btn-deleteH rounded-pillH float-right mr-1 mt-1" dropleft  variant="danger" style="border-radius: 50% !important">
                  <template #button-content  style="border-radius: 50% !important">
                     <i class="far fa-trash-alt text-white"></i>
                  </template>
                  <b-dropdown-item @click="removeDirection" href="javascript: void(0);">Direction</b-dropdown-item>
                  <b-dropdown-item @click="removeDivision" href="javascript: void(0);">Division</b-dropdown-item>
                  <b-dropdown-item @click="removeDepartement" href="javascript: void(0);">Services</b-dropdown-item>
                  <b-dropdown-item @click="removeService" href="javascript: void(0);">Service</b-dropdown-item>
                  <b-dropdown-item @click="removeServices" href="javascript: void(0);">Section</b-dropdown-item>
                </b-dropdown>
                </li>
                <li>
                  <button type="button"  class="d-none btn btn-uploadH rounded-pillH float-right mr-1 mt-1">
                  <i class="fas fa-file-upload  text-white"></i>
                  </button>
                </li>
                <li>
                  <button type="button"  class="d-none btn btn-downloadH rounded-pillH float-right mr-1 mt-1">
                  <i class="fas fa-file-download text-white"></i>
                  </button>
                </li>
                <li>
                  <button type="button"  class="d-none btn btn-printH rounded-pillH float-right mr-1 mt-1">
                  <i class="fas fa-print"></i>
                  </button>
                </li>
              </ul>
                <h4 class="card-title  pb-2">
                     Hiérarchie Details
                </h4>
                <fieldset :disabled="disabled">
                <table class="table table-sm table-head-bg-brand">
                    <thead class="thead-inverse">
                        <tr >
                            <th width="15%"></th>
                            <th width="25%" class="mb-2">Designation</th>
                            <th width="60%" class="mb-2">Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td scope="row" class="align-middle">Direction <input v-model="form.direction.id" class="form-control " type="hidden" ></td>
                            <td><input :class="{'is-invalid': submitted && $v.form.direction.name.$error,}" v-model="form.direction.name" class="form-control " type="text" >
                              <div v-if="submitted && $v.form.direction.name.$error" class="invalid-feedback">
                              <span v-if="!$v.form.direction.name.required">Ce champ est obligatoire.</span>
                              <br />
                              <span v-if="!$v.form.direction.name.maxLength">La taille minimum du champs est:<b>{{ this.vrules.direction.name.maxLength }}</b>Caractères.</span>
                            </div>
                            </td>
                            <td><input :class="{'is-invalid': submitted && $v.form.direction.description.$error,}" v-model="form.direction.description" class="form-control " type="text" ></td>
                        </tr>
                        <tr>
                            <td scope="row" class="align-middle">Division <input v-model="form.division.id" class="form-control " type="hidden" ></td>
                            <td><input :class="{'is-invalid': submitted && $v.form.division.name.$error,}" v-model="form.division.name" class="form-control " type="text" >
                              <div v-if="submitted && $v.form.division.name.$error" class="invalid-feedback">
                              <span v-if="!$v.form.division.name.required">Ce champ est obligatoire.</span>
                              <br />
                              <span v-if="!$v.form.division.name.maxLength">La taille minimum du champs est:<b>{{ this.vrules.division.name.maxLength }}</b>Caractères.</span>
                            </div>
                            </td>
                            <td><input :class="{'is-invalid': submitted && $v.form.division.description.$error,}" v-model="form.division.description" class="form-control " type="text" >
                            </td>
                        </tr>
                        <tr>
                            <td scope="row" class="align-middle">Services <input v-model="form.departement.id" class="form-control " type="hidden" ></td>
                            <td><input :class="{'is-invalid': submitted && $v.form.departement.name.$error,}" v-model="form.departement.name" class="form-control " type="text" >
                              <div v-if="submitted && $v.form.departement.name.$error" class="invalid-feedback">
                              <span v-if="!$v.form.departement.name.required">Ce champ est obligatoire.</span>
                              <br />
                              <span v-if="!$v.form.departement.name.maxLength">La taille minimum du champs est:<b>{{ this.vrules.departement.name.maxLength }}</b>Caractères.</span>
                            </div>
                            </td>
                            <td><input :class="{'is-invalid': submitted && $v.form.departement.description.$error,}" v-model="form.departement.description" class="form-control " type="text" ></td>
                        </tr>
                        <tr>
                            <td scope="row" class="align-middle">Service <input v-model="form.service.id" class="form-control " type="hidden" ></td>
                            <td><input :class="{'is-invalid': submitted && $v.form.service.name.$error,}" v-model="form.service.name" class="form-control " type="text" >
                              <div v-if="submitted && $v.form.service.name.$error" class="invalid-feedback">
                              <span v-if="!$v.form.service.name.required">Ce champ est obligatoire.</span>
                              <br />
                              <span v-if="!$v.form.service.name.maxLength">La taille minimum du champs est:<b>{{ this.vrules.service.name.maxLength }}</b>Caractères.</span>
                            </div>
                            </td>
                            <td><input :class="{'is-invalid': submitted && $v.form.service.description.$error,}" v-model="form.service.description" class="form-control " type="text" ></td>

                        </tr>
                        <tr>
                            <td scope="row" class="align-middle">Section <input v-model="form.section.id" class="form-control " type="hidden" ></td>
                            <td><input :class="{'is-invalid': submitted && $v.form.section.name.$error,}" v-model="form.section.name" class="form-control " type="text" >
                              <div v-if="submitted && $v.form.section.name.$error" class="invalid-feedback">
                              <span v-if="!$v.form.section.name.required">Ce champ est obligatoire.</span>
                              <br />
                              <span v-if="!$v.form.section.name.maxLength">La taille minimum du champs est:<b>{{ this.vrules.section.name.maxLength }}</b>Caractères.</span>
                            </div>
                            </td>
                            <td><input :class="{'is-invalid': submitted && $v.form.section.description.$error,}" v-model="form.section.description" class="form-control " type="text" ></td>

                        </tr>
                    </tbody>
                </table>
                <div class="button-items float-right ">
                   <button type="button"  @click="cancelBtn" v-show="!disabled && ($can('create_hierarchie') || $can('update_hierarchie'))" class="btn btn-light btn-label float-right ml-2">
                      <i class="fas fa-times-circle text-danger label-icon"></i>Annuler
                    </button>
                      <button
                        class="btn btn-primary float-right btn-label"
                        type="button"
                        @click="addStr"
                        v-show="disabledAdd && $can('create_hierarchie')"
                      >
                        <i class="far fa-save label-icon "></i> Enregistrer
                      </button>
                      <button
                        class="btn btn-primary float-right btn-label"
                        type="button"
                        @click="updateStr"
                        v-show="disabledUpdate && $can('update_hierarchie')"
                      >
                        <i class="far fa-save label-icon "></i> Enregistrer
                      </button>
                    </div>
                </fieldset>
            </div>
        </div>
        </div>
    </div>
  </Layout>
</template>